<template>
  <div class="predefined-filters">
    <div class="predefined-filters__title">{{ filledBetDescription.title }}</div>
    <div class="predefined-filters__subtitle">{{ filledBetDescription.description }}</div>
    <ul class="predefined-filters__bull-container">
      <template v-if="activeFilter">
        <li v-for="(bullet, index) in filledBetDescription.bullets" :key="index" class="predefined-filters__bull-item">
          <span v-html="getFormattedBullet(bullet)"></span>
        </li>
      </template>
      <template v-if="!activeFilter">
        <li class="predefined-filters__bull-item">Todos los partidos</li>
        <li class="predefined-filters__bull-item">El partido completo</li>
      </template>
    </ul>
    <div class="predefined-filters__button-bet">
      <span v-html="filledBetDescription.result" />
    </div>
    <div
      v-if="user.premiumAccount"
      class="predefined-filters__button-filter-premium"
      :class="{ backgroundOff: !activeFilter }"
      @click="clickFilter"
    >
      <img
        class="predefined-filters__button-filter-premium__icon-on"
        v-if="activeFilter"
        src="/images/icn_power-off@2x.png"
      />
      <img
        class="predefined-filters__button-filter-premium__icon-on"
        v-if="!activeFilter"
        src="/images/icn_power-on@2x.png"
      />
      <div class="predefined-filters__button-filter-premium__icon-text" v-if="activeFilter">
        Quitar filtros avanzados
      </div>
      <div
        class="predefined-filters__button-filter-premium__icon-text"
        :class="{ colorOff: !activeFilter }"
        v-if="!activeFilter"
      >
        Prender filtros avanzados
      </div>
    </div>
    <div v-else class="predefined-filters__button-filter-free">
      Para ver los datos, haz
      <span class="predefined-filters__button-filter-free__opt-click" @click="clickLogin">click aquí</span> para iniciar
      sesión o crear tu cuenta
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
export default {
  name: 'PredefinedFilters',
  components: {},
  props: {},
  data() {
    return {
      activeFilter: true,
    };
  },
  computed: {
    ...mapState('loginGeneral', ['user']),
    ...mapState('nextGeneral', [
      'betDescription',
      'homeTeamInfo',
      'awayTeamInfo',
      'regularSeasonName',
      'betSubcategoriesObjects',
    ]),
    ...mapGetters('nextGeneral', ['homeTeamStatisticsType', 'visitingTeamStatisticsType']),
    filledBetDescription() {
      const filledBetDescription = {
        title: '',
        description: '',
        bullets: [],
        result: '',
      };
      if (this.betDescription) {
        if (this.betDescription.title) {
          let title = this.betDescription.title;
          title = title.replace('{start}', '1');
          title = title.replace('{end}', '16');
          title = title.replace('{season}', this.regularSeasonName);
          filledBetDescription.title = title;
        }
        filledBetDescription.description = this.betDescription.description || '';
        if (this.betDescription.bullets) {
          this.betDescription.bullets.forEach(bullet => {
            let bulletAux = bullet;
            bulletAux = bulletAux.replace('{home_team}', this.homeTeamInfo.team_name);
            bulletAux = bulletAux.replace('{away_team}', this.awayTeamInfo.team_name);
            filledBetDescription.bullets.push(bulletAux);
          });
        }
        if (this.betDescription.result && this.betDescription.result_category && this.betDescription.operation) {
          this.betSubcategoriesObjects.forEach(subcategory => {
            if (subcategory.generalId === this.betDescription.result_category) {
              subcategory.isBetMainSubcategory = true;
              let result = 0;
              const homeAvg = subcategory.homeTeam[this.homeTeamStatisticsType].avgPostGame;
              const awayAvg = subcategory.visitingTeam[this.visitingTeamStatisticsType].avgPostGame;
              let resultTeam = '';
              switch (this.betDescription.operation) {
                case 'suma':
                  result = homeAvg + awayAvg;
                  break;
                case 'mayor':
                  if (homeAvg === awayAvg) {
                    const homeTeamRanking = subcategory.homeTeam[this.homeTeamStatisticsType].ranking;
                    const awayTeamRanking = subcategory.homeTeam[this.visitingTeamStatisticsType].ranking;
                    resultTeam =
                      homeTeamRanking > awayTeamRanking ? this.homeTeamInfo.acronym : this.awayTeamInfo.acronym;
                  } else if (awayAvg > homeAvg) {
                    result = awayAvg;
                    resultTeam = this.awayTeamInfo.acronym;
                  } else {
                    result = homeAvg;
                    resultTeam = this.homeTeamInfo.acronym;
                  }
                  break;
                case 'local':
                  result = homeAvg;
                  break;
                case 'visitante':
                  result = awayAvg;
                  break;
              }
              let resultText = this.betDescription.result;
              if (this.user.premiumAccount) {
                resultText = this.betDescription.result.replace('{result}', result.toFixed(1));
              } else {
                const hiddenData =
                  '<span style="filter: blur(5px);\n' +
                  '  -webkit-filter: blur(5px);\n' +
                  '  background-color: rgba(255, 255, 255, 0.1);\n' +
                  '  position: relative;\n' +
                  '  z-index: 0;">N/A</span>';
                resultText = resultText.replace('{result}', hiddenData);
                resultText = resultText.replace('{result_team}', hiddenData);
              }
              resultText = resultText.replace('{result_team}', resultTeam);
              resultText = resultText.replace('{home_team}', this.homeTeamInfo.acronym);
              resultText = resultText.replace('{away_team}', this.awayTeamInfo.acronym);
              filledBetDescription.result = `<span>${resultText}</span>`;
            } else {
              subcategory.isBetMainSubcategory = false;
            }
          });
        }
      }
      return filledBetDescription;
    },
  },
  methods: {
    ...mapMutations(['SET_PREMIUM_MESSAGE']),
    ...mapMutations('nextGeneral', ['setBetDescription']),
    ...mapActions('nextGeneral', ['turnOnOffDefaultFilters']),
    clickFilter() {
      this.activeFilter = !this.activeFilter;
      this.turnOnOffDefaultFilters({ command: this.activeFilter ? 'on' : 'off' });
    },
    clickLogin() {
      this.SET_PREMIUM_MESSAGE(true);
    },
    getFormattedBullet(bullet) {
      let formattedBullet = bullet;
      const matches = bullet.match(/\*/g);
      if (matches && matches.length) {
        if (matches.length % 2 === 0) {
          for (let i = 1; i <= matches.length; i++) {
            if (i % 2 === 0) {
              formattedBullet = formattedBullet.replace('*', '</span>');
            } else {
              formattedBullet = formattedBullet.replace('*', '<span style="color: #ffff03" >');
            }
          }
        } else {
          formattedBullet = bullet.replaceAll('*', '');
        }
      }
      return `<span>${formattedBullet}</span>`;
    },
  },
  beforeDestroy() {
    this.setBetDescription(null);
  },
};
</script>

<style lang="scss" scoped>
$blur: 5px;
.colorOff {
  color: #0e7abd;
}
.backgroundOff {
  background: white;
}
.blurred {
  filter: blur($blur);
  -webkit-filter: blur($blur);
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 0;
}
.text-decoration {
  color: #ffff03;
}
.predefined-filters {
  background-color: #001d57;
  width: 100%;
  height: auto;
  border-bottom: 1px solid white;
  &__title {
    width: 100%;
    height: 37px;
    margin-top: 32px;
    font-family: 'Avenir-Pro-Bold';
    font-size: 27px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.15px;
    text-align: center;
    color: #fff;
    @media screen and (max-width: 900px) {
      font-size: 25px;
    }
    @media screen and (max-width: 768px) {
      font-size: 23px;
    }
    @media screen and (max-width: 530px) {
      text-align: left;
      padding-left: 30px;
      height: auto;
      margin-top: 15px;
      font-size: 24px;
      width: 97%;
    }
    @media screen and (max-width: 300px) {
      padding-left: 40px;
      font-size: 19px;
      width: 94%;
    }
  }
  &__subtitle {
    width: 100%;
    height: 24px;
    font-family: 'Avenir-Medium';
    font-size: 21px;
    margin-top: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.12px;
    text-align: center;
    color: #e5e5e5;
    @media screen and (max-width: 900px) {
      font-size: 19px;
    }
    @media screen and (max-width: 768px) {
      font-size: 17px;
    }
    @media screen and (max-width: 530px) {
      text-align: left;
      padding-left: 30px;
      height: auto;
      margin-top: 4px;
      font-size: 19px;
      width: 97%;
    }
    @media screen and (max-width: 300px) {
      padding-left: 40px;
      font-size: 16px;
      width: 94%;
    }
  }
  &__bull-container {
    width: 70%;
    height: auto;
    margin: 0 auto;
    margin-top: 27px;
    @media screen and (max-width: 768px) {
      width: 79%;
    }
    @media screen and (max-width: 530px) {
      width: 97%;
      padding-left: 30px;
      margin-top: 11px;
      margin-left: 0px;
    }
    @media screen and (max-width: 300px) {
      padding-left: 40px;
      width: 94%;
    }
  }
  &__bull-item {
    width: 100%;
    height: auto;
    text-align: left;
    padding-left: 1.28571429em;
    text-indent: -1.28571429em;
    margin-top: 10px;
    font-family: 'Avenir-Medium';
    color: white;
    font-size: 21px;
    list-style-position: inside;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: -0.12px;
    @media screen and (max-width: 900px) {
      font-size: 19px;
    }
    @media screen and (max-width: 768px) {
      font-size: 17px;
    }
    @media screen and (max-width: 530px) {
      font-size: 18px;
    }
    @media screen and (max-width: 300px) {
      font-size: 15px;
    }
  }
  &__button-bet {
    min-width: 188px;
    max-width: 275px;
    height: 48px;
    margin: 0 auto;
    margin-top: 25px;
    text-align: center;
    line-height: 48px;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
    background-color: #feff01;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    font-family: 'Avenir-Pro-Bold';
    letter-spacing: -0.13px;
    text-align: center;
    color: #0f374f;
    @media screen and (max-width: 900px) {
      min-width: 178px;
      max-width: 265px;
      font-size: 20px;
    }
    @media screen and (max-width: 768px) {
      min-width: 118px;
      max-width: 225px;
      font-size: 19px;
    }
    @media screen and (max-width: 530px) {
      min-width: 108px;
      max-width: 205px;
      font-size: 17px;
    }
    @media screen and (max-width: 300px) {
      min-width: 90px;
      max-width: 180px;
      font-size: 15px;
    }
  }
  &__button-filter-premium {
    width: 223px;
    height: 40px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 32px;
    border-radius: 20px;
    border: solid 0.8px #fff;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    font-family: 'Avenir-Demi';
    font-size: 14.2px;
    letter-spacing: -0.08px;
    text-align: center;
    color: #fff;

    @media screen and (max-width: 900px) {
      width: 213px;
      font-size: 13.4px;
    }
    @media screen and (max-width: 768px) {
      min-width: 203px;
      font-size: 13.2px;
    }
    @media screen and (max-width: 530px) {
      width: 268px;
      height: 48px;
      border-radius: 24px;
      border: solid 1px #fff;
      font-size: 17.2px;
      margin-top: 20px;
      margin-bottom: 24px;
    }
    &__icon-on {
      width: 20px;
      margin-top: 9px;
      margin-left: 16px;
      @media screen and (max-width: 530px) {
        width: 26px;
        margin-left: 16px;
      }
    }
    &__icon-text {
      width: 200px;
      height: 24px;
      line-height: 24px;
      margin-top: 7px;
      margin-left: 16px;
      text-align: left;
      @media screen and (max-width: 530px) {
        margin-left: -5px;
        margin-top: 10px;
      }
    }
  }
  &__button-filter-premium:hover {
    cursor: pointer;
  }
  &__button-filter-free {
    width: 378px;
    height: 64px;
    margin: 0 auto;
    margin-top: 40px;
    margin-bottom: 32px;
    padding: 10px 24px 8px 26px;
    border-radius: 12px;
    border: solid 0.8px #fff;
    font-family: 'Avenir-Pro-Medium';
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.44;
    letter-spacing: -0.09px;
    text-align: center;
    color: #fff;
    &__opt-click {
      font-family: 'Avenir-Pro-Bold';
      text-decoration: underline;
      cursor: pointer;
    }
    @media screen and (max-width: 900px) {
      width: 358px;
      font-size: 15px;
    }
    @media screen and (max-width: 768px) {
      width: 338px;
      font-size: 14px;
    }
    @media screen and (max-width: 530px) {
      width: 268px;
      font-size: 12px;
    }
  }
}
</style>
