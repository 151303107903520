<template>
  <section class="container-stats">
    <!-- Header -->
    <div class="columns is-mobile" v-if="!isMobile">
      <div class="tournament-container">
        <div class="tournament-container__tournament">
          <div v-if="!!(tournamentSelected && tournamentSelected.logo)" class="tournament-container__tournament__logo">
            <img :src="tournamentSelected.logo" alt="Tournament logo" />
          </div>
        </div>
      </div>
    </div>
    <div class="columns is-mobile">
      <PredefinedFilters v-if="isFromBet" />
    </div>
    <div class="columns view-container-title is-mobile">
      <div class="column has-text-left">
        <div class="title-wrapper left is-vcentered">
          <template v-if="homeTeam.logo">
            <img :src="homeTeam.logo" alt="Local Team Logo" />
            <span class="is-size-2-tablet is-size-4-mobile acronym-homeTeam">
              {{ homeTeam.acronym }}
            </span>
          </template>
          <template v-else>
            <span class="loading-team">
              -
            </span>
          </template>
        </div>
      </div>
      <div class="column has-text-centered is-2" v-if="!showPostMatchData">
        <div class="vs-container">
          <div class="is-size-3-tablet is-size-4-mobile">VS</div>
          <div v-if="showLive" class="live-indicator is-uppercase">live</div>
        </div>
      </div>
      <div class="column has-text-centered is-3" v-else>
        <div class="vs-container">
          <div class="postmatch-score">
            <div v-if="penaltiesResult" class="penalties">({{ penaltiesResult[0] }})</div>
            <div>{{ getHomeResultGame }}&nbsp;-&nbsp;{{ getAwayResultGame }}</div>
            <div v-if="penaltiesResult" class="penalties">({{ penaltiesResult[1] }})</div>
          </div>
          <div class="indicator-postmatch is-uppercase">Post Partido</div>
        </div>
      </div>
      <div class="column has-text-right">
        <div class="title-wrapper right is-vcentered is-pulled-right">
          <template v-if="visitingTeam.logo">
            <span class="is-size-2-tablet is-size-4-mobile">
              {{ visitingTeam.acronym }}
            </span>
            <img :src="visitingTeam.logo" alt="Visiting Team Logo" />
          </template>
          <template v-else>
            <span class="loading-team">
              -
            </span>
          </template>
        </div>
      </div>
    </div>
    <div v-if="!isFromBet" class="columns view-container-title-menu is-mobile">
      <div class="view-container-title-menu__item" @click="clickMenuOption('stats')">
        <span :class="menuOptionActive == 'stats' ? 'active' : ''">ESTADISTICAS</span>
      </div>
      <div class="view-container-title-menu__sep">|</div>
      <div class="view-container-title-menu__item" @click="clickMenuOption('mxm')">
        <span :class="menuOptionActive == 'mxm' ? 'active' : ''">JUGADA A JUGADA</span>
      </div>
      <div class="view-container-title-menu__sep">|</div>
      <div class="view-container-title-menu__item" @click="clickMenuOption('lineups')">
        <span :class="menuOptionActive == 'lineups' ? 'active' : ''">ALINEACIONES</span>
      </div>
    </div>
    <template v-if="menuOptionActive == 'stats' && !isFromBet">
      <div class="columns is-mobile is-multiple" style="padding: 0px">
        <template v-for="(item, index) in viewOptions">
          <ItemNextMatches
            :item="item"
            :key="index"
            :id="idAssetSelected"
            class="flex-item column"
            style="display:inline-block"
            :size="size"
          >
          </ItemNextMatches>
        </template>
      </div>
    </template>

    <!-- Body -->
    <div
      :class="[
        menuOptionActive != 'lineups' ? 'columns with-border stats is-flex custom-container' : '',
        isMobile ? 'mobile' : '',
      ]"
    >
      <slot name="content"></slot>
    </div>
  </section>
</template>

<script>
import options from '@/data/next/options';
import ItemNextMatches from '@/components/Elements/NextMatches/ItemNextMatches';
import PredefinedFilters from '@/components/Elements/NextMatches/PredefinedFilters';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'ViewContainer',
  props: {
    size: {
      type: Number,
      required: true,
    },
    isFromBet: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemNextMatches,
    PredefinedFilters,
  },
  data: function() {
    return {
      activeMenu: 'stats',
    };
  },
  computed: {
    ...mapState('general', ['tournamentSelected']),
    ...mapState('nextGeneral', ['gameForAnalysis']),
    ...mapGetters('nextGeneral', [
      'homeTeam',
      'visitingTeam',
      'showLive',
      'menuOptionActive',
      'showLiveData',
      'idAssetSelected',
      'showPostMatchData',
      'getHomeResultGame',
      'getAwayResultGame',
    ]),
    viewOptions: () => options.viewOptions,
    isMobile() {
      return this.size < 769;
    },
    penaltiesResult() {
      if (this.gameForAnalysis.penalties_score_home_team && this.gameForAnalysis.penalties_score_visiting_team) {
        return [this.gameForAnalysis.penalties_score_home_team, this.gameForAnalysis.penalties_score_visiting_team];
      }
      return null;
    },
  },
  methods: {
    ...mapMutations('nextGeneral', ['setMenuOptionActive']),
    clickMenuOption(option) {
      this.setMenuOptionActive(option);
    },
  },
};
</script>

<style lang="scss" scoped>
.tournament-container {
  width: 100%;
  height: 60px;
  border-radius: 4px;
  border-top: solid 1px #dedede;
  border-left: solid 1px #dedede;
  border-right: solid 1px #dedede;
  &__tournament {
    padding-right: 10px;
    padding-top: 10px;

    &__logo {
      height: 40px;
      width: 100%;
      & > img {
        height: 100%;
      }
    }
  }
}

.custom-container {
  overflow: -moz-hidden-unscrollable !important;
  overflow: hidden !important;
  overflow: clip !important;
}
.indicator-postmatch {
  font-family: Circular-Std;
  font-size: 14px;
  height: 15px;
  line-height: 15px;
  flex-grow: 0;
}
.postmatch-score {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 3rem !important;
  height: 51px;
  line-height: 51px;
  margin-top: 15px;

  & .penalties {
    font-family: Avenir-Medium, sans-serif;
    font-size: 0.4em;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    margin: 0 6px;
  }
}

.is-vcentered {
  display: flex;
  align-items: center;
}

.acronym-local {
  margin-left: 8px;
}

.view-container-title {
  font-family: 'Circular-Std';
  background-color: #001d57;
  color: white;
  align-items: center;
  justify-content: center;
}
.view-container-title-menu {
  background-color: #213b6e;
  font-family: 'Circular-Std-Medium';
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-size: 14px;
  letter-spacing: 0.07px;
  text-align: center;
  color: #ffffff;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 400px) {
    font-size: 9px;
  }
  &__item {
    width: 33.33%;
    height: 40px;
    line-height: 40px;
    &:hover {
      cursor: pointer;
    }
    & > span.active {
      border-bottom: solid 3px #3ec7ff;
      padding-bottom: 5px;
      padding-left: 2px;
      padding-right: 2px;
      @media screen and (max-width: 768px) {
        border-bottom: solid 2px #3ec7ff;
        padding-bottom: 6px;
      }
    }
  }
  &__sep {
    width: 0.33%;
    font-size: 10px;
    height: 20px;
    margin-top: 15px;
    @media screen and (max-width: 768px) {
      margin-top: 14px;
    }
    @media screen and (max-width: 400px) {
      margin-top: 13px;
    }
  }
}

.with-border {
  border: solid 1px #d7d7d7;
  background-color: #fdfdfd;
  overflow: auto;
  min-height: 50vh;
}

.with-border.mobile {
  min-height: 50vh;
}

@media screen and (width: 1024px) and (height: 1366px) {
  .with-border {
    min-height: 40vh;
  }
}

@media screen and (max-width: 768px) {
  .stats {
    width: 500px !important;
  }
  .indicator-postmatch {
    font-size: 10px !important;
  }
  .postmatch-score {
    font-size: 2.4em !important;
  }
}

@media screen and (max-width: 530px) {
  .stats {
    width: auto !important;
  }
  .container-stats {
    width: 92vw !important;
  }
}

@media screen and (max-width: 400px) {
  .container-stats {
    width: 98% !important;
  }
  .indicator-postmatch {
    font-size: 7px !important;
    letter-spacing: -0.5px;
  }
  .postmatch-score {
    font-size: 1.6em !important;

    & .penalties {
      font-size: 0.65em;
    }
  }
}

.match-live-notification {
  font-family: 'Circular-Std';
  font-size: 0.875em;
  padding: 6px 16px;
  width: 65px;
  height: 30px;
  opacity: 0.8;
  border-radius: 6px;
  border: solid 1px #ffffff;
  color: #ffffff;
}

.custom-section-container {
  padding: 0 2rem;
}

.vs-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.live-indicator {
  padding: 10px 14px;
  font-family: Circular-Std;
  font-size: 12px;
  border-radius: 6px;
  border: solid 1px white;
  flex-grow: 0;
}

.loading-team {
  text-align: center;
  font-size: 50px !important;
}
</style>
